button.logoutButton {
    display: flex;
    padding-left: 0px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.30);
    background: rgba(255, 255, 255, 0.04);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}