.token-manager {
  width: 100%;
  transition: width 0.4s, margin-left 0.4s;
}

.sidebarOpen {
  width: calc(100% - 240px);
  margin-left: 240px;
}

.autorizado {
  width: calc(100% - 60px);
  margin-left: 60px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.card-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 20px;
}

.token-card {
  width: 300px;
  background-color: #008b50;
  color: white;
  border-radius: 8px;
  position: relative;
}

.card-content-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.card-content {
  flex-grow: 1;
}

.document-text {
  margin-top: 16px;
}

.date-text {
  margin-bottom: 16px;
}

.delete-button-wrapper {
  display: flex;
  align-items: center;
  padding-right: 8px;
}

.delete-button {
  color: white;
}
