/* src/components/TwoFactorAuthPage.css */
.two-factor-auth {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #fafafa;
}

.two-factor-auth h2 {
  margin-bottom: 10px;
}

.two-factor-auth p {
  margin-bottom: 20px;
}

.code-input {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px; /* Adicione margem inferior para separar do botão */
}

.code-input input {
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 20px;
  border: 1px solid #00b850;
  border-radius: 8px;
  margin: 0 5px;
}

button {
  background-color: #00b850;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #008b50;
}
