.maintenance {
    width: 100%;
    transition: width 0.4s;
    transition: margin-left 0.4s;
}
  
.sidebarOpen {
    width: calc(100% - 240px);
    margin-left: 240px;
    margin-right: 240px;
    transition: width 0.4s;
    transition: margin-left 0.4s;
}

.autorizado {
    width: calc(100% - 60px);
    margin-left: 60px;
    transition: width 0.4s;
    transition: margin-left 0.4s;
}